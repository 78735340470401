import {Provider} from 'react-redux'
import {CssBaseline, ThemeProvider, createTheme} from '@mui/material'
import {store} from './redux/store'
import './default.css'
import Page from './components/Page'

const theme = createTheme({
  palette: {
    background: {
      default: '#f0f3fb',
      paper: 'white',
    },
    primary: {
      main: '#194c7a',
    },
    secondary: {
      main: '#C62828',
    },
  },
  spacing: 5,
})

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Page />
      </ThemeProvider>
    </Provider>
  )
}

export default App
