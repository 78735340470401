import type {FC} from 'react'
import {Box, Divider, Grid, Hidden, Typography} from '@mui/material'
import aponaLogo from '../assets/APONALogo.png'
import dfkiLogo from '../assets/DFKILogo.png'
import sitLogoSchrift from '../assets/SITLogoSchrift.png'
import ukshLogo from '../assets/UKSHLogo.png'
import PatientList from './selector/PatientList'
import Main from './main/Main'
import Result from './result/Result'

const logoSX = {
  height: '2.5rem',
  display: 'flex',
}

const Page: FC = () => {
  return (
    <>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography variant="h3" component="h1" sx={{fontWeight: 'bold'}}>LENGTH OF STAY PREDICTION</Typography>
      </Box>
      <Divider variant="fullWidth" sx={{height: '.5rem', backgroundColor: 'primary.main', mb: 2}} />
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        alignItems="stretch"
        spacing={2}
        sx={{pb: '5rem'}}
      >
        <Hidden lgDown>
          <Grid item lg={2}>
            <PatientList />
          </Grid>
        </Hidden>
        <Grid item xs={6}>
          <Main />
        </Grid>
        <Grid item xs={6} lg={4}>
          <Result />
        </Grid>
      </Grid>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'primary.main',
          height: '4rem',
          margin: '0 -10px',
        }}
      >
        <Box sx={{display: 'flex', width: '75%', justifyContent: 'space-between', alignItems: 'center'}}>
          <Box sx={{...logoSX, alignItems: 'center', color: 'white'}}><Typography>EINE KOOPERATION VON</Typography></Box>
          <Box sx={logoSX}><img src={sitLogoSchrift} style={{maxHeight: '100%'}} /></Box>
          <Box sx={logoSX}><img src={aponaLogo} style={{maxHeight: '100%'}} /></Box>
          <Box sx={logoSX}><img src={ukshLogo} style={{maxHeight: '100%'}} /></Box>
          <Box sx={logoSX}><img src={dfkiLogo} style={{maxHeight: '100%'}} /></Box>
        </Box>
      </Box>
    </>
  )
}

export default Page
